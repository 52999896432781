<template>
  <div v-if="props.checklist && props.checklist.length" class="patient-information-form-wrapper">
    <div class="form">
      <div class="light-background-section">
        <h5>Insurance Coverage Criteria</h5>
        <hr class="light-hr" />
        <div v-for="currentRequirement in payerQuestions" :key="currentRequirement.id">
          <div v-if="!currentRequirement.invisibleInPortal">
            <p class="accented-paragraph">
              {{ currentRequirement.label }}
            </p>
            <input
              v-model="currentRequirement.options[0].label"
              :type="currentRequirement.options[0].nodeType"
              :placeholder="currentRequirement.label"
              readonly="true" />
            <div v-if="currentRequirement.llmPrediction">
              <p class="llm-prediction">
                <span v-if="currentRequirement.llmPrediction.isHumanReviewRequested" style="color: #ff6347">
                  Review Needed
                </span>
                <span v-if="currentRequirement.llmPrediction.context">
                  Context: {{ currentRequirement.llmPrediction.context }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const payerQuestions = ref([]);

const props = defineProps({
  checklist: {
    type: Array,
    default() {
      return [];
    },
  },
  llmPrediction: {
    type: Array,
    default() {
      return [];
    },
  },
  originalPayerQuestions: {
    type: Array,
    default() {
      return [];
    },
  },
});

onMounted(() => {
  for (const requirement of props.checklist) {
    let payerQuestion = requirement;
    if (props.llmPrediction && props.llmPrediction.length > 0) {
      payerQuestion.llmPrediction = props.llmPrediction.find((prediction) => prediction.answerId === requirement.id);
    }
    let invisibilityCloak = props.originalPayerQuestions.find(
      (originalPayerQuestion) => originalPayerQuestion.requirementRuleName === requirement.id
    );
    payerQuestion.invisibleInPortal = invisibilityCloak ? invisibilityCloak.invisibleInPortal : false;
    payerQuestions.value.push(payerQuestion);
  }
});
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
