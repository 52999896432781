<template>
  <div>
    <div class="patient-information-form-wrapper">
      <div class="patient-information-page-title">
        <h1>Fill In Patient And Prescriber Information</h1>
      </div>
      <div class="form">
        <div class="light-background-section">
          <h5>Optional: Upload patient intake form to populate form below</h5>
          <hr class="light-hr" />
          <TextractOCRComponent />
        </div>
      </div>
      <GreenCirclePreloader v-if="preloader" />
      <div v-else class="form">
        <div id="patient-information-section" class="patient-information-part">
          <span class="header-with-select-box">
            <h5>Patient Information</h5>
            <select id="patient-select" v-model="patientSelection" class="custom-select-arrow">
              <option disabled value="null">Select Patient From Address Book</option>
              <option
                v-for="patient in patientAddressBook.filter(
                  (patient) => patient.firstName && patient.lastName && patient.dob && patient.insuranceId
                )"
                :key="patient.id">
                {{ patient.firstName }} {{ patient.lastName }} ({{ patient.dob }}), {{ patient.insuranceId }}
              </option>
            </select>
          </span>
          <p>Patient Name</p>
          <hr class="bold-hr" />
          <div class="fields-in-row">
            <div class="input-elements-group patient-prefix">
              <label for="prefix">Prefix</label>
              <input id="prefix" v-model="orderInfo.patientInfo.prefix" type="text" placeholder="" />
            </div>
            <div class="input-elements-group patient-first">
              <label for="first">First Name*</label>
              <input id="first" v-model="orderInfo.patientInfo.firstName" type="text" placeholder="" />
              <span v-if="!patientFieldNotEmpty('firstName') && formButtonClicked" class="input-error-notification">
                Please enter patient first name.
              </span>
            </div>
            <div class="input-elements-group patient-middle">
              <label for="middle">Middle Name</label>
              <input id="middle" v-model="orderInfo.patientInfo.middleName" type="text" placeholder="" />
            </div>
            <div class="input-elements-group patient-last">
              <label for="last">Last Name*</label>
              <input id="last" v-model="orderInfo.patientInfo.lastName" type="text" placeholder="" />
              <span v-if="!patientFieldNotEmpty('lastName') && formButtonClicked" class="input-error-notification">
                Please enter patient last name.
              </span>
            </div>
            <div class="input-elements-group patient-suffix">
              <label for="suffix">Suffix</label>
              <input id="suffix" v-model="orderInfo.patientInfo.suffix" type="text" placeholder="" />
            </div>
          </div>

          <hr class="light-hr" />
          <div class="fields-in-row">
            <div class="input-elements-group">
              <label for="insurance-provider">Insurance Provider*</label>
              <input id="insurance-provider" v-model="orderInfo.insuranceProvider" type="text" placeholder="" />
              <span v-if="!fieldNotEmpty('insuranceProvider') && formButtonClicked" class="input-error-notification">
                Please enter patient Insurance Provider.
              </span>
            </div>
            <div class="input-elements-group">
              <label for="insurance-member-id">Insurance Member ID*</label>
              <input id="insurance-member-id" v-model="orderInfo.patientInfo.insuranceId" type="text" placeholder="" />
              <span v-if="!patientFieldNotEmpty('insuranceId') && formButtonClicked" class="input-error-notification">
                Please enter patient Insurance Member ID.
              </span>
            </div>
            <!-- <div class="input-elements-group">
              <label for="insurance-member-name">Name of the Insured*</label>
              <input
                id="insurance-member-name"
                v-model="orderInfo.patientInfo.insuranceMemberName"
                type="text"
                placeholder="" />
              <span
                v-if="!patientFieldNotEmpty('insuranceMemberName') && formButtonClicked"
                class="input-error-notification">
                Please enter name of the insured member on the card.
              </span>
            </div> -->
            <div class="input-elements-group">
              <label for="benefit-type">Benefit Category*</label>
              <select id="benefit-type" v-model="orderInfo.benefitType" class="custom-select-arrow">
                <option disabled value="null">Select Benefit Type</option>
                <option v-for="(value, key) in benefitTypes" :key="key" :value="key">{{ value }}</option>
              </select>
              <span v-if="!fieldNotEmpty('benefitType') && formButtonClicked" class="input-error-notification">
                Please select whether medication is covered under pharmacy or medical benefits.
              </span>
            </div>
          </div>

          <div class="fields-in-row">
            <div v-if="!isMedical" class="input-elements-group">
              <label for="pcn">Rx PCN</label>
              <input id="pcn" v-model="orderInfo.processorControlNumber" type="text" placeholder="" />
            </div>
            <div v-if="!isMedical" class="input-elements-group">
              <label for="bin">Rx BIN Number*</label>
              <input id="bin" v-model="orderInfo.bin" type="text" placeholder="" />
              <span v-if="!fieldNotEmpty('bin') && formButtonClicked" class="input-error-notification">
                Please enter Rx BIN.
              </span>
            </div>
            <div class="input-elements-group">
              <label for="insurance-plan-type">Plan Type</label>
              <input id="insurance-plan-type" v-model="orderInfo.insurancePlanType" type="text" placeholder="" />
              <!-- <span v-if="!fieldNotEmpty('insurancePlanType') && formButtonClicked" class="input-error-notification">
                Please enter patient Insurance Plan Type.
              </span> -->
            </div>
            <div class="input-elements-group">
              <label for="group-number">Group Number (Rx GRP)</label>
              <input id="group-number" v-model="orderInfo.grpNumber" type="text" placeholder="" />
            </div>
          </div>

          <div class="fields-in-row">
            <div class="input-elements-group">
              <label for="date-of-birth">Date of Birth*</label>
              <input id="date-of-birth" v-model="orderInfo.patientInfo.dob" type="date" placeholder="" />
              <span v-if="!patientFieldNotEmpty('dob') && formButtonClicked" class="input-error-notification">
                Please enter patient date of birth.
              </span>
            </div>
            <div class="input-elements-group">
              <label for="phone">Phone*</label>
              <input id="phone" v-model="orderInfo.patientInfo.phone" type="text" placeholder="123-345-6789" />
              <span v-if="!patientFieldNotEmpty('phone') && formButtonClicked" class="input-error-notification">
                Please enter patient phone number.
              </span>
            </div>
            <!-- <div class="input-elements-group">
              <label for="ssn">Social Security Number*</label>
              <input id="ssn" v-model="orderInfo.patientInfo.ssn" type="text" placeholder="" />
              <span v-if="!patientFieldNotEmpty('ssn') && formButtonClicked" class="input-error-notification">
                Please enter patient social security number.
              </span>
            </div> -->
          </div>
          <p>Patient Gender*</p>
          <div class="patient-gender-wrapper">
            <div>
              <input id="male" v-model="orderInfo.patientInfo.gender" type="radio" name="gender" value="Male" />
              <label for="male">Male</label>
            </div>
            <div>
              <input id="female" v-model="orderInfo.patientInfo.gender" type="radio" name="gender" value="Female" />
              <label for="female">Female</label>
            </div>
            <div>
              <input
                id="unspecified"
                v-model="orderInfo.patientInfo.gender"
                type="radio"
                name="gender"
                value="Unspecified" />
              <label for="unspecified">Unspecified</label>
            </div>
            <span v-if="!patientFieldNotEmpty('gender') && formButtonClicked" class="input-error-notification">
              Please enter patient gender.
            </span>
          </div>

          <br />

          <p>Patient Address</p>
          <hr class="bold-hr" />
          <div class="input-elements-group">
            <label for="address-line-1">Address Line 1*</label>
            <input id="address-line-1" v-model="orderInfo.patientInfo.address1" type="text" placeholder="" />
            <span v-if="!patientFieldNotEmpty('address1') && formButtonClicked" class="input-error-notification">
              Please enter patient address.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="address-line-2">Address Line 2</label>
            <input id="address-line-2" v-model="orderInfo.patientInfo.address2" type="text" placeholder="" />
          </div>

          <div class="fields-in-row">
            <div class="input-elements-group">
              <label for="city">City*</label>
              <input id="city" v-model="orderInfo.patientInfo.city" type="text" placeholder="" />
              <span v-if="!patientFieldNotEmpty('city') && formButtonClicked" class="input-error-notification">
                Please enter patient city.
              </span>
            </div>

            <div class="input-elements-group">
              <label for="insurance-state">Patient Insurance State*</label>
              <select id="insurance-state" v-model="orderInfo.patientInfo.state" class="custom-select-arrow">
                <option disabled value="null">Select State</option>
                <option v-for="state in states" :key="state">{{ state }}</option>
              </select>
              <span v-if="!patientFieldNotEmpty('state') && formButtonClicked" class="input-error-notification">
                Please choose patient insurance state.
              </span>
            </div>

            <div class="input-elements-group">
              <label for="zip">ZIP*</label>
              <input id="zip" v-model="orderInfo.patientInfo.zip" type="text" placeholder="" />
              <span v-if="!patientFieldNotEmpty('zip') && formButtonClicked" class="input-error-notification">
                Please choose patient zip.
              </span>
            </div>
          </div>
        </div>

        <div class="patient-information-part">
          <span class="header-with-select-box">
            <h5>Requesting Provider or Prescriber</h5>
            <select id="provider-select" v-model="providerSelection" class="custom-select-arrow">
              <option disabled value="null">Select Provider</option>
              <option
                v-for="provider in providerAddressBook.filter(
                  (provider) => provider.providerFirstName && provider.providerLastName && provider.npiNumber
                )"
                :key="provider.id">
                {{ provider.providerFirstName }} {{ provider.providerLastName }}, {{ provider.npiNumber }}
              </option>
            </select>
          </span>
          <hr class="bold-hr" />
          <div class="fields-in-row">
            <div class="input-elements-group">
              <label for="provider-first">Provider First Name*</label>
              <input
                id="provider-first"
                v-model="orderInfo.providerInfo.providerFirstName"
                type="text"
                placeholder="" />
              <span
                v-if="!providerFieldNotEmpty('providerFirstName') && formButtonClicked"
                class="input-error-notification">
                Please enter provider's first name.
              </span>
            </div>
            <div class="input-elements-group">
              <label for="provider-last">Provider Last Name*</label>
              <input id="provider-last" v-model="orderInfo.providerInfo.providerLastName" type="text" placeholder="" />
              <span
                v-if="!providerFieldNotEmpty('providerLastName') && formButtonClicked"
                class="input-error-notification">
                Please enter provider's last name.
              </span>
            </div>
            <!-- <div class="input-elements-group">
              <label for="provider-title">Provider Title*</label>
              <select id="provider-title" v-model="orderInfo.providerInfo.providerTitle" class="custom-select-arrow">
                <option disabled value="null">Select Title</option>
                <option v-for="title in providerTitleOptions" :key="title">{{ title }}</option>
              </select>
              <span
                v-if="!providerFieldNotEmpty('providerTitle') && formButtonClicked"
                class="input-error-notification">
                Please choose provider's title.
              </span>
            </div>
            <div class="input-elements-group">
              <label for="provider-email">Email*</label>
              <input id="provider-email" v-model="orderInfo.providerInfo.email" type="text" placeholder="" />
              <span v-if="!providerFieldNotEmpty('email') && formButtonClicked" class="input-error-notification">
                Please enter provider's email.
              </span>
            </div> -->
          </div>

          <div class="fields-in-row">
            <!-- <div class="input-elements-group">
              <label for="contact-name">Contact Name*</label>
              <input id="contact-name" v-model="orderInfo.providerInfo.contactName" type="text" placeholder="" />
              <span v-if="!providerFieldNotEmpty('contactName') && formButtonClicked" class="input-error-notification">
                Please enter contact name.
              </span>
            </div> -->
            <div class="input-elements-group">
              <label for="contact-phone">Contact Phone*</label>
              <input
                id="contact-phone"
                v-model="orderInfo.providerInfo.contactPhone"
                type="text"
                placeholder="123-345-6789" />
              <span v-if="!providerFieldNotEmpty('contactPhone') && formButtonClicked" class="input-error-notification">
                Please enter contact phone number.
              </span>
            </div>
            <div class="input-elements-group">
              <label for="contact-fax">Contact Fax*</label>
              <input
                id="contact-fax"
                v-model="orderInfo.providerInfo.contactFax"
                type="text"
                placeholder="123-345-6789" />
              <span v-if="!providerFieldNotEmpty('contactFax') && formButtonClicked" class="input-error-notification">
                Please enter contact fax.
              </span>
            </div>
            <div class="input-elements-group">
              <label for="npi">NPI Number*</label>
              <input id="npi" v-model="orderInfo.providerInfo.npiNumber" type="text" placeholder="" />
              <span v-if="!providerFieldNotEmpty('npiNumber') && formButtonClicked" class="input-error-notification">
                Please enter NPI Number.
              </span>
            </div>
          </div>
          <!-- <div class="fields-in-row">
            <div class="input-elements-group">
              <label for="tin">TIN*</label>
              <input id="tin" v-model="orderInfo.providerInfo.tin" type="text" placeholder="" />
              <span v-if="!providerFieldNotEmpty('tin') && formButtonClicked" class="input-error-notification">
                Please enter TIN.
              </span>
            </div>
            <div class="input-elements-group">
              <label for="specialty">Specialty*</label>
              <input id="specialty" v-model="orderInfo.providerInfo.specialty" type="text" placeholder="" />
              <span v-if="!providerFieldNotEmpty('specialty') && formButtonClicked" class="input-error-notification">
                Please enter prescribing provider's specialty.
              </span>
            </div>
          </div> -->

          <!-- <div class="fields-in-row">
            <div class="input-elements-group">
              <label for="deaNumber">Drug Enforcement Administration Number</label>
              <input id="deaNumber" v-model="orderInfo.providerInfo.deaNumber" type="text" placeholder="" />
            </div>
            <div class="input-elements-group">
              <label for="license-number">License Number*</label>
              <input id="license-number" v-model="orderInfo.providerInfo.licenseNumber" type="text" placeholder="" />
              <span
                v-if="!providerFieldNotEmpty('licenseNumber') && formButtonClicked"
                class="input-error-notification">
                Please enter license number.
              </span>
            </div>
          </div> -->

          <!-- <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="office-name">Practice Title*</label>
            <input id="office-name" v-model="orderInfo.providerInfo.officeName" type="text" placeholder="" />
            <span v-if="!providerFieldNotEmpty('officeName') && formButtonClicked" class="input-error-notification">
              Please enter practice title.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="office-phone">Office Phone*</label>
            <input
              id="office-phone"
              v-model="orderInfo.providerInfo.officePhone"
              type="text"
              placeholder="123-345-6789" />
            <span v-if="!providerFieldNotEmpty('officePhone') && formButtonClicked" class="input-error-notification">
              Please enter phone number.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="fax">Fax*</label>
            <input id="fax" v-model="orderInfo.providerInfo.fax" type="text" placeholder="123-345-6789" />
            <span v-if="!providerFieldNotEmpty('fax') && formButtonClicked" class="input-error-notification">
              Please enter fax.
            </span>
          </div>
        </div> -->

          <p>Requesting Provider or Prescriber Address</p>
          <hr class="bold-hr" />

          <div class="input-elements-group">
            <label for="provider-address-line-1">Address Line 1*</label>
            <input
              id="provider-address-line-1"
              v-model="orderInfo.providerInfo.providerAddress1"
              type="text"
              placeholder="" />
            <span
              v-if="!providerFieldNotEmpty('providerAddress1') && formButtonClicked"
              class="input-error-notification">
              Please enter address.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="provider-address-line-2">Address Line 2</label>
            <input
              id="provider-address-line-2"
              v-model="orderInfo.providerInfo.providerAddress2"
              type="text"
              placeholder="" />
          </div>

          <div class="fields-in-row">
            <div class="input-elements-group">
              <label for="provider-city">City*</label>
              <input id="provider-city" v-model="orderInfo.providerInfo.providerCity" type="text" placeholder="" />
              <span v-if="!providerFieldNotEmpty('providerCity') && formButtonClicked" class="input-error-notification">
                Please enter city.
              </span>
            </div>

            <div class="input-elements-group">
              <label for="provider-insurance-state">Provider State*</label>
              <select
                id="provider-insurance-state"
                v-model="orderInfo.providerInfo.providerState"
                class="custom-select-arrow">
                <option disabled value="null">Select State</option>
                <option v-for="state in states" :key="state">{{ state }}</option>
              </select>
              <span
                v-if="!providerFieldNotEmpty('providerState') && formButtonClicked"
                class="input-error-notification">
                Please choose state.
              </span>
            </div>

            <div class="input-elements-group">
              <label for="provider-zip">ZIP*</label>
              <input id="provider-zip" v-model="orderInfo.providerInfo.providerZip" type="text" placeholder="" />
              <span v-if="!providerFieldNotEmpty('providerZip') && formButtonClicked" class="input-error-notification">
                Please enter ZIP.
              </span>
            </div>
          </div>
        </div>

        <div class="patient-information-part">
          <h5>Medical Information</h5>
          <hr class="bold-hr" />
          <div class="fields-in-row">
            <div class="input-elements-group medication">
              <label for="medication">Medication*</label>
              <input id="medication" v-model="orderInfo.medication" type="text" placeholder="" />
              <span v-if="!fieldNotEmpty('medication') && formButtonClicked" class="input-error-notification">
                Please enter medication.
              </span>
            </div>
            <div class="input-elements-group strength">
              <label for="strength">Strength*</label>
              <input id="strength" v-model="orderInfo.strength" type="text" placeholder="" />
              <span v-if="!fieldNotEmpty('strength') && formButtonClicked" class="input-error-notification">
                Please enter strength.
              </span>
            </div>
            <!-- <div class="input-elements-group route">
              <label for="route">Administration*</label>
              <input id="route" v-model="orderInfo.routeAdministration" type="text" placeholder="" />
              <span v-if="!fieldNotEmpty('routeAdministration') && formButtonClicked" class="input-error-notification">
                Please enter route of administration.
              </span>
            </div> -->
          </div>

          <div class="fields-in-row">
            <!-- <div class="input-elements-group frequency">
              <label for="frequency">Dosing Schedule*</label>
              <input id="frequency" v-model="orderInfo.frequency" type="text" placeholder="" />
              <span v-if="!fieldNotEmpty('frequency') && formButtonClicked" class="input-error-notification">
                Please enter frequency.
              </span>
            </div> -->
            <div class="input-elements-group therapy-length">
              <label for="therapy-length">Duration (days)*</label>
              <input id="therapy-length" v-model="orderInfo.expectedLengthOfTherapy" type="text" placeholder="" />
              <span
                v-if="!fieldNotEmpty('expectedLengthOfTherapy') && formButtonClicked"
                class="input-error-notification">
                Please enter length of therapy in days.
              </span>
            </div>
            <div class="input-elements-group quantity">
              <label for="quantity">Quantity*</label>
              <input id="quantity" v-model="orderInfo.quantityPer30Days" type="text" placeholder="" />
              <span v-if="!fieldNotEmpty('quantityPer30Days') && formButtonClicked" class="input-error-notification">
                Please enter quantity.
              </span>
            </div>
            <div class="input-elements-group quantity">
              <label for="quantity-qualifier">Drug Quantity Qualifier*</label>
              <input id="quantity-qualifier" v-model="orderInfo.drugQuantityQualifier" type="text" placeholder="" />
              <span
                v-if="!fieldNotEmpty('drugQuantityQualifier') && formButtonClicked"
                class="input-error-notification">
                Please enter quantity qualifier.
              </span>
            </div>
          </div>

          <!-- <div class="fields-in-row">
            <div class="input-elements-group date-started">
              <label for="date-started">Date Started*</label>
              <input id="date-started" v-model="orderInfo.startDate" type="date" placeholder="" />
              <span v-if="!fieldNotEmpty('startDate') && formButtonClicked" class="input-error-notification">
                Please enter date started.
              </span>
            </div>
            <div class="input-elements-group stage-of-therapy">
              <label for="stage-of-therapy">Stage of Therapy <small>(Initial/Continuation)</small>*</label>
              <select id="stage-of-therapy" v-model="orderInfo.stageOfTherapy" class="custom-select-arrow">
                <option disabled value="null">Select Stage of Therapy</option>
                <option v-for="stage in stagesOfTherapy" :key="stage">
                  {{ stage }}
                </option>
              </select>
              <span v-if="!fieldNotEmpty('stageOfTherapy') && formButtonClicked" class="input-error-notification">
                Please select stage of therapy.
              </span>
            </div>
          </div>

          <div class="fields-in-row">
            <div class="input-elements-group height">
              <label for="height">Height (inches)*</label>
              <input id="height" v-model="orderInfo.patientInfo.height" type="text" placeholder="" />
              <span v-if="!patientFieldNotEmpty('height') && formButtonClicked" class="input-error-notification">
                Please enter height.
              </span>
            </div>
            <div class="input-elements-group weight">
              <label for="weight">Weight (lb)*</label>
              <input id="weight" v-model="orderInfo.patientInfo.weight" type="text" placeholder="" />
              <span v-if="!patientFieldNotEmpty('weight') && formButtonClicked" class="input-error-notification">
                Please enter weight.
              </span>
            </div>
            <div class="input-elements-group drug-allergies">
              <label for="drug-allergies">Drug Allergies</label>
              <input id="drug-allergies" v-model="orderInfo.patientInfo.drugAllergies" type="text" placeholder="" />
            </div>
          </div> -->
        </div>

        <!-- <div class="patient-information-part">
          <h5>Pharmacy Information <span v-if="orderInfo.benefitType === 'Medical benefits'">(Optional)</span></h5>
          <hr class="bold-hr" />
          <p>
            By providing the pharmacy information, you authorize Do Prior Auth to contact the pharmacy of your choice on
            your behalf to report the status of your Prior Authorization as well as follow-up.
          </p>
          <PharmacyLookupComponent
            :is-editable="true"
            :form-button-clicked="formButtonClicked && orderInfo.benefitType === 'Pharmacy benefits'"
            :pharmacy-selection="orderInfo.pharmacyInfo"
            @pharmacy-selected="setPharmacySelection" />
        </div> --->

        <div class="patient-information-part">
          <h5>Patient Diagnosis Information</h5>
          <hr class="bold-hr" />
          <p>
            Please list all diagnosis being treated with the requested drug and corresponding ICD-10 codes. If the
            condition being treated with the requested drug is a symptom e.g. anorexia, weight loss, shortness of
            breath, chest pain, nausea, etc. provide the diagnosis causing the symptom(s) if known.
          </p>
          <DiagnosisSelectionPreviewComponent
            :medication="orderInfo.medication"
            :primary-diagnosis="orderInfo.primaryDiagnosis"
            :other-diagnosis="orderInfo.otherDiagnosis"
            :is-editable="true"
            :form-button-clicked="formButtonClicked"
            @update-primary-diagnosis="updatePrimaryDiagnosis"
            @update-other-diagnosis="updateOtherDiagnosis"
            @add-other-diagnosis="addOtherDiagnosis" />
        </div>

        <div class="light-background-section">
          <h5>Request For Expedited Review</h5>
          <hr class="light-hr" />
          <div class="expedited-checkmark-wrapper">
            <input id="expedited-review-24h" v-model="orderInfo.expeditedReviewWithin24h" type="checkbox" />
            <label for="expedited-review-24h" class="expedited-review-1">
              CHECK THIS BOX IF YOU BELIEVE YOU NEED A DECISION WITHIN 24 HOURS (if you have a supporting statement from
              your prescriber, attach it to this request).
            </label>
          </div>
          <span v-if="orderInfo.expeditedReviewWithin24h || orderInfo.expeditedReviewCheck">
            <p>Must provide reason for expedited review:</p>
            <input
              id="expedited-review-reason"
              v-model="orderInfo.expeditedReviewStatement"
              type="text"
              placeholder="" />
            <p>
              NOTE: If you are asking for a formulary or tiering exception, your prescriber MUST provide a statement
              supporting your request. Requests that are subject to prior authorization (or any other utilization
              management requirement), may require supporting information. Your prescriber may use the attached
              “Supporting Information for an Exception Request or Prior Authorization” to support your request.
            </p>
          </span>
        </div>
      </div>
    </div>

    <div>
      <RadioSelectFlowOptionComponent
        :data-pull-preloader="dataPullPreloader"
        :data-pull-error="dataPullError"
        :specific-data-pull-error="specificDataPullError"
        @set-auto-filling-option="setAutoFillingOption"
        @pull-auto-filling-data="pullAutoFillingData" />
    </div>

    <div class="patient-information-form-wrapper">
      <div class="form">
        <div>
          <div class="buttons-wrapper">
            <button
              class="return-button"
              :class="{ 'button-clicked': draftSavingInProgress }"
              :disabled="draftSavingInProgress"
              @click="saveDraft">
              Save Draft
            </button>
            <button class="next-button" :disabled="draftSavingInProgress" @click="handleNextClicked">Next</button>
          </div>
        </div>
        <div class="errors-container">
          <span v-if="error" class="input-error-notification">
            Sorry, something went wrong. Please contact us at
            <a href="mailto:founders@lamarhealth.com"> founders@lamarhealth.com</a> or try again later
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onBeforeMount, computed } from "vue";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import DiagnosisSelectionPreviewComponent from "@/components/DiagnosisSelectionPreviewComponent";
import RadioSelectFlowOptionComponent from "@/components/RadioSelectFlowOptionComponent";
import TextractOCRComponent from "@/components/TextractOCRComponent";
// import PharmacyLookupComponent from "@/components/PharmacyLookupComponent";
import { usaStates } from "@/utils/usaStates";
import { storeToRefs } from "pinia";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import { useRequirementsStore } from "@/stores";
import { orderInfoService } from "@/services/orderInfoService";
import { initializePatientInfo, initializeProviderInfo, tryParseApiErrors } from "@/utils";
const { orderInfo } = storeToRefs(useOrderInfoStore());
const { requirementsData } = storeToRefs(useRequirementsStore());

const states = ref([]);
const preloader = ref(false);
const formButtonClicked = ref(false);
const draftSavingInProgress = ref(false);
const error = ref(false);
// const providerTitleOptions = ["MD", "DO", "NP", "PA", "PharmD"];
// const stagesOfTherapy = ["New Therapy (initiation)", "Renewal (continuation)"];
const benefitTypes = { medical_benefits: "Medical", pharmacy_benefits: "Pharmacy" };

const emit = defineEmits(["addData"]);
const isMedical = computed(() => orderInfo.value.benefitType === "medical_benefits");

onBeforeMount(() => {
  if (!orderInfo.value.patientInfo) {
    initializePatientInfo();
  }
  if (!orderInfo.value.providerInfo) {
    initializeProviderInfo();
  }
});

onMounted(() => {
  orderInfo.value.formStep = "Patient and Provider Information";
  for (let stateData of Object.values(usaStates)) {
    states.value.push(stateData.name);
  }
  getAddressBooks();
});

async function saveDraft() {
  if (
    patientFieldNotEmpty("firstName") &&
    patientFieldNotEmpty("lastName") &&
    patientFieldNotEmpty("insuranceId") &&
    patientFieldNotEmpty("dob")
  ) {
    await savePartialOrderInfo();
  } else {
    const patientInfoSection = document.getElementById("patient-information-section");
    patientInfoSection.scrollIntoView({ behavior: "smooth" });
  }
}

async function savePartialOrderInfo() {
  draftSavingInProgress.value = true;
  if (!orderInfo.value.orderKey) {
    const newOrder = await orderInfoService.createEmptyOrder(orderInfo.value);
    orderInfo.value.orderKey = newOrder.orderKey;
    try {
      error.value = false;
      const savedPartialOrder = await orderInfoService.saveOrder(orderInfo.value.orderKey, orderInfo.value);
      orderInfo.value.patientInfo.id = savedPartialOrder.patientInfo.id;
      if (savedPartialOrder.providerInfo && savedPartialOrder.providerInfo.id) {
        orderInfo.value.providerInfo.id = savedPartialOrder.providerInfo.id;
      }
    } catch (err) {
      error.value = true;
    }
  } else {
    try {
      error.value = false;
      const savedPartialOrder = await orderInfoService.saveOrder(orderInfo.value.orderKey, orderInfo.value);
      orderInfo.value.patientInfo.id = savedPartialOrder.patientInfo.id;
      if (savedPartialOrder.providerInfo && savedPartialOrder.providerInfo.id) {
        orderInfo.value.providerInfo.id = savedPartialOrder.providerInfo.id;
      }
    } catch (err) {
      error.value = true;
    }
  }
  setTimeout(() => {
    draftSavingInProgress.value = false;
  }, 1000);
}

const patientAddressBook = ref([]);
const providerAddressBook = ref([]);
const providerSelection = ref(null);
const patientSelection = ref(null);
const selectedDiagnoses = ref([]);
const autoFillingOption = ref("manualEntry");
const dataPullPreloader = ref(false);
const dataPullError = ref(false);
const specificDataPullError = ref(null);

async function getAddressBooks() {
  preloader.value = true;
  const addressBooks = await orderInfoService.getAvailableAddressBooks();
  providerAddressBook.value = addressBooks.providerAddressBook;
  patientAddressBook.value = addressBooks.patientAddressBook;
  preloader.value = false;
}

function setAutoFillingOption(option) {
  autoFillingOption.value = option;
}

async function pullAutoFillingData(data) {
  draftSavingInProgress.value = true;
  dataPullPreloader.value = true;
  if (!orderInfo.value.orderKey) {
    const newOrder = await orderInfoService.createEmptyOrder(orderInfo.value);
    orderInfo.value.orderKey = newOrder.orderKey;
    try {
      dataPullError.value = false;
      specificDataPullError.value = null;
      await orderInfoService.pullAutofillingData(orderInfo.value.orderKey, {
        autoFillingOption: autoFillingOption.value,
        data: data,
      });
    } catch (err) {
      dataPullError.value = true;
      specificDataPullError.value = tryParseApiErrors(err);
    }
  } else {
    try {
      dataPullError.value = false;
      specificDataPullError.value = null;
      await orderInfoService.pullAutofillingData(orderInfo.value.orderKey, {
        autoFillingOption: autoFillingOption.value,
        data: data,
      });
    } catch (err) {
      dataPullError.value = true;
      specificDataPullError.value = tryParseApiErrors(err);
    }
  }
  draftSavingInProgress.value = false;
  dataPullPreloader.value = false;
}

const handleNextClicked = async () => {
  formButtonClicked.value = true;
  await new Promise((resolve) => setTimeout(resolve, 0));
  const missingInput = document.getElementsByClassName("input-error-notification");
  if (missingInput.length === 0) {
    await savePartialOrderInfo();
    emit("addData", selectedDiagnoses.value, autoFillingOption.value);
  } else {
    setTimeout(() => {
      const missingInput = document.getElementsByClassName("input-error-notification");
      if (missingInput.length > 0) {
        const parentDiv = missingInput[0].parentNode;
        window.scrollTo({
          top: parentDiv.offsetTop,
          behavior: "smooth",
        });
      }
    }, 0);
  }
};

// Validations
function fieldNotEmpty(field) {
  const value = orderInfo.value[field];
  return value !== null && value.trim() !== "";
}

function patientFieldNotEmpty(field) {
  const value = orderInfo.value.patientInfo[field];
  return value !== null && value.trim() !== "";
}

function providerFieldNotEmpty(field) {
  const value = orderInfo.value.providerInfo[field];
  return value !== null && value.trim() !== "";
}

// function setPharmacySelection(pharmacyInfo) {
//   orderInfo.value.pharmacyInfo = pharmacyInfo;
// }

watch(providerSelection, (newVal) => {
  if (newVal) {
    const selectedProvider = providerAddressBook.value.find(
      (provider) => `${provider.providerFirstName} ${provider.providerLastName}, ${provider.npiNumber}` === newVal
    );
    if (selectedProvider.id) {
      orderInfo.value.providerInfo = selectedProvider;
      orderInfo.value.providerInfo.id = selectedProvider.id;
    }
  }
});

watch(patientSelection, (newVal) => {
  if (newVal) {
    const selectedPatient = patientAddressBook.value.find(
      (patient) => `${patient.firstName} ${patient.lastName} (${patient.dob}), ${patient.insuranceId}` === newVal
    );
    if (selectedPatient.id) {
      const copyOfSelectedPatient = { ...selectedPatient };
      delete copyOfSelectedPatient.id;
      const keepPatientId = orderInfo.value.patientInfo.id;
      orderInfo.value.patientInfo = copyOfSelectedPatient;
      orderInfo.value.patientInfo.id = keepPatientId;
    }
  }
});

function updateSelectedDiagnoses(icd10Code) {
  selectedDiagnoses.value = selectedDiagnoses.value.filter((item) => {
    return item.diagnosis.icd10Code.split(".")[0] !== icd10Code.split(".")[0];
  });
  const foundItem = requirementsData.value.diagnosisRequirements.find((item) => {
    return item.diagnosis.icd10Code.split(".")[0] === icd10Code.split(".")[0];
  });
  if (foundItem) {
    selectedDiagnoses.value.push(foundItem);
  }
}

function updatePrimaryDiagnosis({ icd10Code, diagnosis }) {
  if (!icd10Code || !diagnosis) {
    updateSelectedDiagnoses(orderInfo.value.primaryDiagnosis.icd10Code);
  } else {
    updateSelectedDiagnoses(icd10Code);
  }
  orderInfo.value.primaryDiagnosis.icd10Code = icd10Code;
  orderInfo.value.primaryDiagnosis.diagnosis = diagnosis;
}

function updateOtherDiagnosis({ index, icd10Code, diagnosis }) {
  if (!icd10Code || !diagnosis) {
    updateSelectedDiagnoses(orderInfo.value.otherDiagnosis[index]["icd10Code"]);
    orderInfo.value.otherDiagnosis.splice(index, 1);
    orderInfo.value.otherDiagnosis.push({
      diagnosis: null,
      icd10Code: null,
    });
  } else {
    updateSelectedDiagnoses(icd10Code);
    const insertIndex = orderInfo.value.otherDiagnosis.findIndex((item) => !item.diagnosis);
    orderInfo.value.otherDiagnosis[insertIndex]["icd10Code"] = icd10Code;
    orderInfo.value.otherDiagnosis[insertIndex]["diagnosis"] = diagnosis;
  }
}

function addOtherDiagnosis() {
  orderInfo.value.otherDiagnosis.push({ icd10Code: null, diagnosis: null });
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
