<template>
  <PriorHeader />
  <div class="submission-status-wrapper">
    <div class="shadow-ellipse shadow-ellipse-right"></div>
    <div class="shadow-ellipse"></div>
    <h1>All Submissions</h1>
    <div v-if="!preloader" class="search-container">
      <form @submit.prevent="runSearch">
        <div>
          <label for="search-term">
            Search by Keywords in Submission <br />
            <small>(eg Keycode, Medication, Patient Name, Payor, Provider)</small>
          </label>
          <input id="search-term" v-model="searchFormData.searchTerm" type="text" placeholder="" />
        </div>
        <div class="half-width">
          <div>
            <label for="status">Filter by Status</label>
            <select id="status" v-model="searchFormData.status" class="custom-select-arrow">
              <option value="">All Submissions</option>
              <option value="INCOMPLETE">Incomplete</option>
              <option value="NOT_SUPPORTED">Payor/Medication not supported</option>
              <option value="REQUIRES_REVIEW">Requires review</option>
              <option value="SENT_TO_PLAN">Sent to plan</option>
              <option value="RESPONSE_RECEIVED">Response received</option>
              <option value="APPROVED">Approved</option>
              <option value="DENIED">Denied</option>
              <option value="ARCHIVED">Archived</option>
            </select>
          </div>
          <div>
            <label for="ordering">Filter by Date</label>
            <select id="ordering" v-model="searchFormData.orderBy" class="custom-select-arrow">
              <option value="">Most Recent</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>
        <div style="grid-column: 2" class="buttons-container">
          <button type="submit">Search</button>
          <button @click="clearSearch">Clear Search</button>
        </div>
      </form>
    </div>
    <div v-if="preloader">
      <GreenCirclePreloader />
    </div>
    <div v-if="!preloader && availableOrders.length === 0">
      <h2>No Submissions Available</h2>
    </div>
    <ModalDialog :modal-active="modalActive" @close="toggleModal">
      <template #modal-header> Confirmation </template>
      <p>Are you sure you want to delete the submission? This action can not be reversed.</p>
      <template #modal-buttons>
        <div v-if="miniPreloader">
          <MiniGreenCirclePreloader style="margin: 0" />
        </div>
        <div v-else style="grid-column: 2">
          <button style="margin-right: 10px" class="modal-button" @click="deleteSubmission()">Delete</button>
          <button class="modal-button" @click="toggleModal()">Cancel</button>
        </div>
      </template>
    </ModalDialog>
    <div v-if="!preloader && availableOrders.length > 0">
      <div v-for="order in availableOrders" :key="order.orderKey">
        <div class="card">
          <div class="card-container">
            <div>
              <div class="card-header">
                <span style="grid-column: 1"><strong>Patient Name:</strong> {{ getPatientName(order) }} </span>
                <span style="grid-column: 2"><strong>Order Status:</strong> {{ order.status }}</span>
              </div>
              <div class="card-content">
                <div class="card-row request-text">
                  <span><strong>Date Created:</strong> {{ convertDateFormat(order.createdOn) }}</span>
                  <span><strong>Insurance:</strong> {{ titleCaseString(order.insuranceProvider) }}</span>
                  <span><strong>Key:</strong> {{ order.orderKey }}</span>
                </div>
                <div class="card-row request-text">
                  <span><strong>Date Modified:</strong> {{ convertDateFormat(order.modifiedOn) }}</span>
                  <span><strong>Plan Type:</strong> {{ order.insurancePlanType }}</span>
                  <span
                    ><strong>Medication:</strong> {{ titleCaseString(order.medication)
                    }}<span v-if="order.extraMedications?.length"
                      >{{ ", " + order.extraMedications.map((med) => titleCaseString(med["medication"])).join(", ") }}
                    </span>
                  </span>
                </div>
                <div class="card-row request-text">
                  <span v-if="expiresSoon(order)"><strong class="red-text">Expires soon!</strong></span>
                  <hr v-else class="light-hr" />
                  <span>
                    <strong>Prior Auth Number:</strong>
                    {{ order.orderSubmission ? order.orderSubmission.priorAuthNumber : "" }}
                  </span>
                  <span>
                    <strong>Expires on:</strong>
                    {{
                      !order.orderSubmission
                        ? ""
                        : order.orderSubmission.priorAuthExpiration
                        ? convertDateFormat(order.orderSubmission.priorAuthExpiration)
                        : ""
                    }}
                  </span>
                </div>
                <div class="card-row request-text">
                  <span><strong>Type:</strong> {{ titleCaseString(order.benefitType) }}</span>
                </div>
                <div class="card-row request-text">
                  <span>
                    <strong>Status Notes:</strong>
                    {{ getOrderSubmissionStatus(order) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons-container">
            <button class="view-details-button" @click="selectSubmission(order)">View Details</button>
            <button class="view-details-button" @click="selectForDeletion(order)">Delete</button>
            <button
              v-if="order.orderSubmission && order.orderSubmission.responseDocumentUrl"
              class="view-details-button"
              @click="clickLink(order)">
              View Decision
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!preloader" class="pagination">
    <div v-for="index in displayedPages" :key="index">
      <button
        v-if="typeof index === 'number'"
        class="page-button"
        :class="{ active: index === currentPage }"
        @click="getAvailableOrders(index)">
        {{ index }}
      </button>
      <span v-else>
        {{ index }}
      </span>
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import { ref, onMounted } from "vue";
import PriorHeader from "@/components/PriorHeader";
import PriorFooter from "@/components/PriorFooter";
import { convertDateFormat, titleCaseString } from "@/utils";
import { orderInfoService } from "@/services/orderInfoService";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import MiniGreenCirclePreloader from "@/components/MiniGreenCirclePreloader.vue";
import { useSelectedSubmissionStore } from "@/stores/selectedSubmissionStore";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import ModalDialog from "@/components/ModalDialog";
import { computed } from "vue";

const router = useRouter();

const { selectedSubmission } = storeToRefs(useSelectedSubmissionStore());

const availableOrders = ref([]);
const preloader = ref(false);
const miniPreloader = ref(false);
const pageCount = ref(1);
const currentPage = ref(1);
const modalActive = ref(false);
const orderToDelete = ref(null);

const searchFormData = ref({
  searchTerm: "",
  status: "",
  orderBy: "",
});

onMounted(() => {
  getAvailableOrders(currentPage.value);
});

const searchRunning = ref(false);

const getOrderSubmissionStatus = (order) => {
  if (order.orderSubmission) {
    const status = order.orderSubmission.status || "";
    const notes = order.orderSubmission.notes || "";
    return status + notes;
  }
};

function clearSearch() {
  if (searchRunning.value) {
    searchRunning.value = false;
    searchFormData.value = {
      searchTerm: "",
      status: "",
      orderBy: "",
    };
    getAvailableOrders(1);
  }
}

function clickLink(order) {
  if (order.orderSubmission && order.orderSubmission.responseDocumentUrl) {
    window.open(order.orderSubmission.responseDocumentUrl, "_blank");
  }
}

function runSearch() {
  searchRunning.value = true;
  getAvailableOrders(1);
}

async function getAvailableOrders(index) {
  currentPage.value = index;
  preloader.value = true;
  const response = searchRunning.value
    ? await orderInfoService.searchOrders(searchFormData.value, index)
    : await orderInfoService.getListOfOrders(index);
  availableOrders.value = [...response.results];
  pageCount.value = response.count ? Math.ceil(response.count / 10) : 1;
  await new Promise((resolve) => setTimeout(resolve, 0));
  window.scrollTo(0, 0);
  preloader.value = false;
}

function getPatientName(order) {
  return order.patientInfo && order.patientInfo.firstName && order.patientInfo.lastName
    ? `${order.patientInfo.firstName} ${order.patientInfo.lastName}`
    : "Patient Information Form Not Completed";
}

function selectSubmission(order) {
  selectedSubmission.value = order;
  if (
    ["Approved", "Denied", "Response received", "Sent to plan", "Payor/Medication not supported", "Archived"].includes(
      order.status
    )
  ) {
    router.push({ name: "submission-details", params: { id: selectedSubmission.value.orderKey } });
  } else {
    router.push({ name: "check-my-coverage", params: { id: selectedSubmission.value.orderKey } });
  }
}

async function deleteSubmission() {
  miniPreloader.value = true;
  await orderInfoService.deleteOrder(orderToDelete.value.orderKey);
  miniPreloader.value = false;
  getAvailableOrders(currentPage.value);
  toggleModal();
}

const expiresSoon = (order) => {
  if (!order.priorAuthExpiration) return false;
  const expirationDate = new Date(order.priorAuthExpiration);
  const today = new Date();
  const timeDifference = expirationDate.getTime() - today.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  return daysDifference <= 7;
};

const selectForDeletion = (order) => {
  orderToDelete.value = order;
  toggleModal();
};

const toggleModal = () => {
  modalActive.value = !modalActive.value;
  if (!modalActive.value) {
    orderToDelete.value = null;
  }
};

const displayedPages = computed(() => {
  const pages = [];

  pages.push(1, 2, 3);

  for (let i = currentPage.value - 3; i <= currentPage.value + 3; i++) {
    pages.push(i);
  }

  pages.push(pageCount.value - 2, pageCount.value - 1, pageCount.value);

  let uniquePages = [...new Set(pages)].filter((page) => page >= 1 && page <= pageCount.value);
  uniquePages.sort((a, b) => a - b);

  const finalPages = [];
  let lastPage = 0;
  uniquePages.forEach((page) => {
    if (page - lastPage > 1 && lastPage !== 0) {
      finalPages.push("...");
    }
    finalPages.push(page);
    lastPage = page;
  });

  return finalPages;
});
</script>

<style lang="scss" scoped>
@import "../styles/pages/_submission-status.scss";
</style>
